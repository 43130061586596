<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#097392"
      spinner="bar-fade-scale" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start v-if="top">
      <v-flex xs12 align-self-start v-if="top.image">
        <v-img cover height="450px" class="align-end" :src="mediaURL + top.image">
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16 v-if="$route.query.k == 0">
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Webinars</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-start wrap pt-4>
          <v-flex xs12 sm12 md8 lg8 align-self-center text-justify text-left pr-md-4>
            <span style="font-family: TitLight; font-size: 25px; color: black" :style="{
              'font-size':
                $vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                      ? '20px'
                      : $vuetify.breakpoint.name == 'lg'
                        ? '20px'
                        : '35px',
            }">
              Hello!
              <br />
              On the occasion of <i> World Wetlands Day </i>, Speleological
              Association of India is proud to present our very first Webinar on
              <b>
                Diving in the Subterranean Wetlands by Commander Vishwanath
                Rajan</b>. Cdr. Vishwanath Rajan is an ex-naval officer who has an
              extensive career and a deep-rooted passion in diving. Founder and
              Director of Scuba Evolution India Pvt. Ltd., he has many stories
              to tell about all things diving! He has led various expeditionary
              operations and training camps while in the Indian Navy, and has
              led divers into new and unknown territories. The _subterranean
              karst wetlands_ are a type of underground wetland system, with or
              without a surface water component, commonly associated with caves
              or other underground cavities. Out of all the karst landscape
              features, the Ramsar Convention on Wetlands (Resolution VI.5)
              recognises the subterranean karst wetlands as the wetlands of
              international importance. Subterranean wetlands are largely
              unattended and understudied. This World Wetlands Day, lets take a
              dive with Vishwanath Rajan into these underwater wonders.
              <br />
              <br />
              <b>Date and Time: 5 February 2022, 6 PM IST</b>

              <br />

              <br />
              <br />

              <b>Speleological Association of India</b>
              <br />
              Website:
              <a style="color:blue !important" href="https://speleoindia.org.in/">www.speleoindia.org</a>

              <br />
              Instagram:
              <a style="color:blue !important" target="_blank"
                href="https://instagram.com/speleoindia?utm_medium=copy_link">https://instagram.com/speleoindia?utm_medium=copy_link</a>

              <br />
              Facebook:
              <a style="color:blue !important" target="_blank"
                href="https://www.facebook.com/speleoindia/">https://www.facebook.com/speleoindia/</a>
            </span>
          </v-flex>
          <v-flex xs12 md4 lg4 py-2 py-md-0 align-self-start v-if="evimg">
            <v-img :src="evimg"></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16 v-else-if="$route.query.k == 1">
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Upcoming Events</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-start wrap pt-4>
          <v-flex xs12 sm12 md8 lg8 align-self-center text-justify text-left pr-md-4>
            <span style="font-family: TitLight; font-size: 25px; color: black" :style="{
              'font-size':
                $vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                      ? '20px'
                      : $vuetify.breakpoint.name == 'lg'
                        ? '20px'
                        : '35px',
            }">
              Dear all,
              <br />
              This <b>World Wildlife Day</b>, celebrated on 3rd March 2022, the
              Speleological Association of India is happy to bring you our next
              webinar on
              <b>"Cavernicoles: Denizens of the Dark" by Ms. Amruta
                Dhamorikar</b>. Ms. Amruta is an ecologist presently working as a consultant at
              AECOM India Pvt. Ltd, Mumbai. She has a deep-rooted passion for
              bio-speleology, especially cave-dwelling invertebrates. Her
              preliminary work on cave crickets and her contribution in
              describing Ravi Sankaran's Cave Shrimp (Caridina ravisankarani)
              from the limestone caves of Andaman Islands is evident of her
              interest in invertebrate diversity and cave conservation. Cave and
              karst landforms are distributed widely around the world, forming
              an integral component of the global biodiversity. One of the
              essential functions of these subterranean habitats is to provide a
              refuge for a wide range of flora and fauna. These cave-dwelling
              animals are exceptional in their display of behaviour and
              exclusive morphological adaptions. In the hostile environment of
              caves, these animals adapted to live in caves are commonly known
              as cavernicoles. According to the IUCN Species Survival Commission
              Cave Invertebrate Specialist Group, one of the most significant
              gaps in cave conservation biology is invertebrates. So, this World
              Wildlife Day, let us walk through these extraordinary lives of the
              cave invertebrates as Amruta Dharmorikar sheds some light on a
              Life in Darkness.
              <br />
              <br />
              <b>Date and Time: 12 March 2022, 6 PM IST</b>

              <br />

              <br />
              <br />

              <b>Speleological Association of India</b>
              <br />
              Website:
              <a style="color:blue !important" href="https://speleoindia.org.in/">www.speleoindia.org</a>

              <br />
              Instagram:
              <a style="color:blue !important" target="_blank"
                href="https://instagram.com/speleoindia?utm_medium=copy_link">https://instagram.com/speleoindia?utm_medium=copy_link</a>

              <br />
              Facebook:
              <a style="color:blue !important" target="_blank"
                href="https://www.facebook.com/speleoindia/">https://www.facebook.com/speleoindia/</a>
            </span>
          </v-flex>
          <v-flex xs12 md4 lg4 py-2 py-md-0 align-self-start v-if="evimg">
            <v-img :src="require('./../../assets/images/saiii.jpeg')"></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16 v-else-if="$route.query.k == 2">
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Upcoming Events</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-start wrap pt-4>
          <v-flex xs12 sm12 md8 lg8 align-self-center text-justify text-left pr-md-4>
            <span style="font-family: TitLight; font-size: 25px; color: black" :style="{
              'font-size':
                $vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                      ? '20px'
                      : $vuetify.breakpoint.name == 'lg'
                        ? '20px'
                        : '35px',
            }">
              Dear all,
              <br />
              We are pleased to announce our next Webinar
              <b>"Discover Karst and Cave Geology" by Dr. Jaishri Sanwal</b> on
              3rd April 2022 at 6 PM IST.
              <br />
              <br />
              The fascination for nature and curiosity to explore commences the
              journey of a geologist. Nature often surprises a geologist by
              creating incredible secrets on the earth that are hidden in the
              diverse landscape and environmental systems. One such hidden
              environment on the earth is "The Cave," - the ‘Dark Continent’ or
              the ‘Lost World’. The geological community has consistently been
              inquisitive in investigating the science behind the formation of
              caves, their structures, geomorphology, and their potential in
              geological sciences. The scientific study of caves, known as
              speleology, is an integrated science based on geology, hydrology,
              biology, and archaeology and thus holds particular interest for
              geologists
              <br />
              <br />
              Dr Jaishri Sanwal is a cave geologist, recipient of the DST Women
              Scientist Fellowship currently working at Geodynamics Unit in
              Jawaharlal Nehru Centre for Advanced Scientific Research,
              Bangalore. Her research focuses on a new exciting frontier in
              geodynamics, identifying the significant linkages between climate,
              surface processes, and tectonics. This Geologists Day 2022, Dr
              Sanwal will take us through the different cave formations'
              development, geological evolution, and the mineral deposition and
              decorations that adorn cave ceilings and finely sculptured walls
              carved out by the action of water. The role of caves as a
              potential proxy or archive for climate and tectonics will be
              emphasized during her talk.

              <br />
              <br />
              <b>Date and Time: 3rd April 2022, 6 PM IST</b>

              <br />
              <br />
              <b>For any details, write to us at </b>
              <a style="color:blue !important" href="mailto:speleoindia@gmail.com">speleoindia@gmail.com
              </a>

              <br />
              <b> Note:</b> For those interested in receiving participation
              certificates, registration for the webinar is necessary.
              <br />
              <br />

              <b>Speleological Association of India</b>
              <br />
              Website:
              <a style="color:blue !important" href="https://speleoindia.org.in/">www.speleoindia.org</a>

              <br />
              Instagram:
              <a style="color:blue !important" target="_blank"
                href="https://instagram.com/speleoindia?utm_medium=copy_link">https://instagram.com/speleoindia?utm_medium=copy_link</a>

              <br />
              Facebook:
              <a style="color:blue !important" target="_blank"
                href="https://www.facebook.com/speleoindia/">https://www.facebook.com/speleoindia/</a>
            </span>
          </v-flex>
          <v-flex xs12 md4 lg4 py-2 py-md-0 align-self-start v-if="evimg">
            <v-img :src="require('./../../assets/images/karst.jpeg')"></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16 v-else-if="$route.query.k == 3">
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Upcoming Events</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-center wrap pt-4>
          <v-flex xs12 sm12 align-self-start text-justify text-left pr-md-4>
            <span style="font-family: TitLight; font-size: 25px; color: black" :style="{
              'font-size':
                $vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                      ? '20px'
                      : $vuetify.breakpoint.name == 'lg'
                        ? '20px'
                        : '35px',
            }">
              Contest Alert! Dear All, as part of the celebration of
              <b>International Art Day and the International Year for Caves and
                Karst</b>, Speleological Association India has organized a painting and
              photography contest, open for all! The theme is "Wonders of the
              Dark".

              <!-- To participate and win exciting prizes, simply fill the
              Google form
              <a
                style="color:blue !important"
                target="_blank"
                href="https://forms.gle/zsMmA8KXW2yZxzVY9"
                >(https://forms.gle/zsMmA8KXW2yZxzVY9)</a
              >
              and submit your entries before 20th April! Only one entry per
              participant. Winners will be announced on 24th April. -->

              <br />
              <br />
              <b>For any details, write to us at </b>
              <a style="color:blue !important" href="mailto:speleoindia@gmail.com">speleoindia@gmail.com
              </a>

              <br />

              <br />

              <b>Speleological Association of India</b>
              <br />
              Website:
              <a style="color:blue !important" href="https://speleoindia.org.in/">www.speleoindia.org</a>

              <br />
              Instagram:
              <a style="color:blue !important" target="_blank"
                href="https://instagram.com/speleoindia?utm_medium=copy_link">https://instagram.com/speleoindia?utm_medium=copy_link</a>

              <br />
              Facebook:
              <a style="color:blue !important" target="_blank"
                href="https://www.facebook.com/speleoindia/">https://www.facebook.com/speleoindia/</a>
            </span>
          </v-flex>
          <v-flex xs12 md4 lg4 py-2 pa-2 pt-4 align-self-start v-if="evimg">
            <v-img :src="require('./../../assets/images/eapril.jpeg')"></v-img>
          </v-flex>
          <v-flex xs12 md4 lg4 py-2 pa-2 pt-4 align-self-start v-if="evimg">
            <v-img :src="require('./../../assets/images/eapril2.jpeg')"></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16 v-else-if="$route.query.k == 4">
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Upcoming Events</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-center wrap pt-4>
          <v-flex xs12 sm12 align-self-start text-justify text-left pr-md-4>
            <span style="font-family: TitLight; font-size: 25px; color: black" :style="{
              'font-size':
                $vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                      ? '20px'
                      : $vuetify.breakpoint.name == 'lg'
                        ? '20px'
                        : '35px',
            }">
              An aquifer is a body of rock capable of containing and
              transmitting large quantities of water. Most caves are created at
              or just below the water table in the zone of saturation. Caves are
              the largest pathway within the aquifer. This unique connection is
              responsible for the region's hydrology and provides suitable
              habitats for the highly specialized stygofauna. As part of
              celebrating the International year of caves and karst (IYCK),
              let's take a tour of the unique ecosystems of the planet Earth on
              21st June 2022, at 6 PM (IST) through a webinar on
              <b>"Aquifers and caves: the groundwater perspective" </b> by Mr
              Divyanshu Pawar. Divyanshu comes from an educational background of
              a bachelor's in Geology and a Masters in Ecology, Society, and
              Sustainable Development and is interested in Transdisciplinary
              studies. He has been working for 4 + years in the industry with
              NGOs, Think tanks, Academic institutions, and Independent
              consultants. His understanding of the field and transdisciplinary
              studies grew as he worked with multiple Environmental and Social
              NGOs with people from diverse backgrounds from an early age. He
              has extensively worked on Urban springs throughout these years,
              and also his master's thesis was on Geology, Ecology, and Social
              Understanding of these springs in an urban setting. An aspiring
              Ecohydrologist, he is currently working with the Bombay
              environmental Action group on groundwater aspects of laterite
              plateaus.

              <br />
              <br />

              <b>For any details, write to us at </b>
              <a style="color:blue !important" href="mailto:speleoindia@gmail.com">speleoindia@gmail.com
              </a>

              <br />

              <br />

              <b>Speleological Association of India</b>
              <br />
              Website:
              <a style="color:blue !important" href="https://speleoindia.org.in/">www.speleoindia.org</a>

              <br />
              Instagram:
              <a style="color:blue !important" target="_blank"
                href="https://instagram.com/speleoindia?utm_medium=copy_link">https://instagram.com/speleoindia?utm_medium=copy_link</a>

              <br />
              Facebook:
              <a style="color:blue !important" target="_blank"
                href="https://www.facebook.com/speleoindia/">https://www.facebook.com/speleoindia/</a>
            </span>
          </v-flex>
          <v-flex xs12 md4 lg4 py-2 pa-2 pt-4 align-self-start v-if="evimg">
            <v-img :src="evimg"></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16 v-else-if="$route.query.k == 5">
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Upcoming Events</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-center wrap pt-4>
          <v-flex xs12 sm12 md8 lg8 align-self-center text-justify text-left pr-md-4>
            <span style="font-family: TitLight; font-size: 25px; color: black" :style="{
              'font-size':
                $vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                      ? '20px'
                      : $vuetify.breakpoint.name == 'lg'
                        ? '20px'
                        : '35px',
            }">
              Most plants are either insect-pollinated or wind-pollinated. In
              insect-pollinated plants, insects, especially bees, transport
              pollen from one flower to another. On the other hand,
              wind-pollinated plants produce large amounts of pollen because the
              probability is small that an individual pollen grain will land on
              another flower of the same species. Because of their tiny size,
              pollen grains are quickly dried out and destroyed. Thus, they have
              evolved a callous and resistant outer "shell," which preserves
              well and enables scientists to concentrate the pollen for
              research. Fossil pollen is an essential data for reconstructing
              past vegetation. Because vegetation is sensitive to climate,
              fossil pollen is very important proxy data for reconstructing past
              climates. <br> <br>
              Dr Mahi Bansal is passionate about researching the life
              histories of plants. The position of the continents on the globe
              we see today was not the same millions of years ago. The shifts in
              the geographic location of the continents and the changes in
              climatic conditions over millions of years had a substantial
              impact on the evolution, distribution and ecological tolerance of
              plants. Dr Mahi Bansal investigates plants' evolutionary and
              migratory history by studying pollen remnants trapped under rock
              and soil deposition, in subterranean habitats such as mines.
              Besides other outcrops, pollen grains can be well preserved in
              lignite mines. <br> <br> Dr Mahi will share her experiences recovering
              fossilized pollen from rock/soil strata deposited at subterranean
              depths, far beneath the Earth's surface. She will tell us that
              despite their tiny size, pollen packs a lot of biological and
              geological information about prehistoric times. Mahi says that
              pollen relics can even reconstruct a picture of the entire forests
              and provide information about the plants of the bygone era. Let's
              listen to her and know how pollen opens windows into millions of
              years of history.

              <br />
              <br />
              <b>Date and Time: 26th November 2022, 6 PM IST</b>

              <br />
              <br />

              <b>Register here: </b>
              <a style="color:blue !important"
                href="https://forms.gle/fWT7BTrkf9q8aYeCA">https://forms.gle/fWT7BTrkf9q8aYeCA
              </a>
              <br />
              <br />
              <b>Webinar link: </b>
              <a style="color:blue !important"
                href="https://meet.google.com/mbd-yizh-dsk">https://meet.google.com/mbd-yizh-dsk
              </a>
              <br />
              <br />

              <b>Speleological Association of India</b>
              <br />
              Website:
              <a style="color:blue !important" href="https://speleoindia.org.in/">www.speleoindia.org</a>

              <br />
              Instagram:
              <a style="color:blue !important" target="_blank"
                href="https://instagram.com/speleoindia?utm_medium=copy_link">https://instagram.com/speleoindia?utm_medium=copy_link</a>

              <br />
              Facebook:
              <a style="color:blue !important" target="_blank"
                href="https://www.facebook.com/speleoindia/">https://www.facebook.com/speleoindia/</a>
            </span>
          </v-flex>
          <v-flex xs12 md4 lg4 py-2 pa-2 pt-4 align-self-start v-if="evimg">
            <v-img :src="require('./../../assets/images/eNov.jpg')"></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16 v-else-if="$route.query.k == 6">
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Upcoming Events</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-center wrap pt-4>
          <v-flex xs12 sm12 md8 lg8 align-self-center text-justify text-left pr-md-4>
            <span style="font-family: TitLight; font-size: 25px; color: black" :style="{
              'font-size':
                $vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                      ? '20px'
                      : $vuetify.breakpoint.name == 'lg'
                        ? '20px'
                        : '35px',
            }">
              Seacoasts are the major geomorphic systems with abundant landforms. They occur in different types, shapes,
              origins, and functions and are the ecotones formed at the intercept of marine and terrestrial environments.
              Sea or littoral caves are one type of coastal landform which are apparent in the rocky shores, typically
              formed by wave actions. These caves are not only an excellent example of the geologic event but also provide
              crucial information about the paleoclimate of the region and function as habitats to many marine and
              terrestrial species. Conservation of these critical habitats and mapping them is essentially the need of the
              hour. With her immense passion for the subject, Ms Dhanusha Kawalkar will take us on a whirl to give more
              insights about these less-understood habitats and why studying them is crucial. <br> <br>
              Ms Kawalkar is keen on understanding caves, subterranean systems and their ecology. She is a researcher and
              PhD Scholar at Sálim Ali Centre for Ornithology and Natural History (SACON), India. She is also the
              co-founder of Speleological Association of India, playing a vital role in day-to-day functioning of the
              organization. She has studied caves (coastal and inland) and cave fauna in the Andaman Islands and
              Maharashtra. Based on her experience working with the cave-dwelling Edible-nest Swiftlet (Aerodramus
              fuciphagus) as part of her doctoral research, Ms. Kawalkar is now studying Indian Swiftlet (Aerodramus
              unicolor) in the caves on the offshore islands of Maharashtra. <br> <br>
              Let's listen to her and learn more about the underexplored habitat of coastal caves!

              <br />
              <br />
              <b>Date and Time: 25th March 2023, 6 PM IST</b>

              <br />
              <br />

              <b>Register here: </b>
              <a style="color:blue !important"
                href="https://tinyurl.com/coastalcaves">https://tinyurl.com/coastalcaves
              </a>
              <br />
              <br />
              <b>Webinar link: </b>
              <a style="color:blue !important"
                href="meet.google.com/ntm-duwz-nhy">meet.google.com/ntm-duwz-nhy
              </a>
              <br />
              <br />

              <b>Speleological Association of India</b>
              <br />
              Website:
              <a style="color:blue !important" href="https://speleoindia.org.in/">www.speleoindia.org</a>

              <br />
              Instagram:
              <a style="color:blue !important" target="_blank"
                href="https://instagram.com/speleoindia?utm_medium=copy_link">https://instagram.com/speleoindia?utm_medium=copy_link</a>

              <br />
              Facebook:
              <a style="color:blue !important" target="_blank"
                href="https://www.facebook.com/speleoindia/">https://www.facebook.com/speleoindia/</a>
            </span>
          </v-flex>
          <v-flex xs12 md4 lg4 py-2 pa-2 pt-4 align-self-start v-if="evimg">
            <v-img :src="require('./../../assets/images/eMarch.jpg')"></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap pa-16 justify-center v-else>
      <v-flex xs12 pa-16 align-self-center>
        <span style="font-family:TitSemibold;font-size:18px">OOPS!. Nothing here!</span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      membership: [],
      top: [],
      evimg: require("./../../assets/images/ev.jpg"),
    };
  },

  methods: {},
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
</style>
